import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

const NewsletterPage = () => {
  return (
    <Layout>
      <SEO title="Newsletter" />
      <h3 className="">Join my newsletter</h3>
      <iframe
        title="Substack"
        width="480"
        height="320"
        src="https://jsfour.substack.com/embed"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </Layout>
  )
}

export default NewsletterPage
